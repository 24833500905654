






























































































































import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { Core } from "@/store/core";

import moment from "moment";
import { Evolution } from "@/models/buffalo";
import { App } from "@/store/app";
import BodyLength from '@/components/Component/Core/Evo/BodyLength.vue'
import _ from 'lodash'
@Component({
    components: {
        BodyLength
    },
  
})
export default class TabBuff extends Vue {
    @Prop({
        default: false,
    })
    privateMode!: boolean;
    form: Evolution | any = {};
    private unEdit: boolean = true;
    private response: boolean = false;

    graghKK: any = {
        series: [],
        chartOptions: {
            chart: {
                height: 350,
                type: "line",
            },
            
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: "55%",
                    endingShape: "rounded",
                    dataLabels: {
                        position: "top", // top, center, bottom
                    },
                },
            },
            stroke: {
                width: [0, 4],
            },
            title: {
                text: "น้ำหนัก ที่เพิ่มขึ้น",
            },
            dataLabels: {
                enabled: true,
                formatter: function (val: any) {
                    return val + " กก. / วัน";
                },
                enabledOnSeries: [1],
            },
            xaxis: {
                categories: ["แรกเกิด", "อายุ 240 วัน", "อายุ 400 วัน", "อายุ 600 วัน"],
            },
            yaxis: [{
                    title: {
                        text: "น้ำหนัก (กก.)",
                    },
                },
                {
                    opposite: true,
                    title: {
                        text: "อัตราการเจริญเติบโต (กก./วัน)",
                    },
                },
            ],
            tooltip: {
                y: [{
                        formatter: function (val: any) {
                            return val + " กก.";
                        },
                    },
                    {
                        formatter: function (val: any) {
                            return val + " กก. / วัน";
                        },
                    },
                ],
            },
        },
    };
    graghCM: any = {
        series: [],
        chartOptions: {
            chart: {
                type: "bar",
                height: 350,
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: "55%",
                    endingShape: "rounded",
                },
            },
            dataLabels: {
                enabled: true,
                style: { fontSize: "12px", colors: ["#fff"] },
                formatter: function (val: any) {
                    return val + " ซม.";
                },
                offsetY: -20,
            },
            stroke: {
                show: true,
                width: 2,
                colors: ["transparent"],
            },
            title: {
                text: "ความกว้างรอบอกม ความยาวลำตัวและความสูง",
            },
            xaxis: {
                categories: ["แรกเกิด", "อายุ 240 วัน", "อายุ 400 วัน", "อายุ 600 วัน"],
            },
            yaxis: {
                title: {
                    text: "หน่วย (ซม.)",
                },
            },
            fill: {
                opacity: 1,
            },
            tooltip: {
                y: {
                    formatter: function (val: any) {
                        return val + " ซม.";
                    },
                },
            },
        },
    };

    responseCM:any = false

    graphCM_data:any = {}

    filterDataSeries(data:any,name:String){
        let response =  _.filter(data,{name:name})
        return (response[0])?response[0]:null
    }

    async getGraphCM(){
      this.graphCM_data = {
        "chest": this.filterDataSeries(this.graghCM.series,'ความกว้างรอบอก'),
          "body": this.filterDataSeries(this.graghCM.series,'ความยาวลำตัว'),
         "height": this.filterDataSeries(this.graghCM.series,'ความสูง')
      }
      
    }

    private async created() {
        await this.run();
        await this.getGraphCM();
        this.responseCM = true;
    }
    private async run() {
        await App.onLoad();
        let buffalo = this.$route.query.id;
        this.form = await Core.getHttp(`/user/buffalo/evolution/${buffalo}/`);
        this.response = true;
        await this.generateSeries();
        await App.offLoad();
    }

    @Watch("form.birthday")
    async onChangeDate(val: string) {
        this.form.age = moment().diff(val, "years", false);
    }

    private async updateBuffalo() {
        let update = await Core.putHttp(
            `/user/buffalo/evolution/${this.form.id}/`,
            this.form
        );
        if (update.id) {
            await this.run();
            this.unEdit = true;
            await App.success("บันทึกข้อมูลสำเร็จ");
            await location.reload();
        } else {
            await App.error("โปรดระบุข้อมูลให้ครบถ้วน");
        }
    }

    get evo0() {
        return this.form.width0 ||
            this.form.weight0 ||
            this.form.width0 ||
            this.form.length0 ?
            false :
            true;
    }

    get evo240() {
        return this.form.width240 ||
            this.form.weight240 ||
            this.form.width240 ||
            this.form.length240 ?
            false :
            true;
    }

    get evo400() {
        return this.form.width400 ||
            this.form.weight400 ||
            this.form.width400 ||
            this.form.length400 ?
            false :
            true;
    }

    get evo600() {
        return this.form.width600 ||
            this.form.weight600 ||
            this.form.width600 ||
            this.form.length600 ?
            false :
            true;
    }
    get editMode() {
        return this.unEdit ? "border-success" : "border-edit";
    }
    async generateSeries() {
        let kk = [{
                name: "น้ำหนัก",
                type: "column",
                data: [
                    this.form.weight0,
                    this.form.weight240,
                    this.form.weight400,
                    this.form.weight600,
                ],
            },
            {
                name: "การเจริญเติบโต",
                type: "line",
                data: [
                    0,
                    this.calculate(this.form.weight0, this.form.weight240, 240),
                    this.calculate(this.form.weight0, this.form.weight400, 400),
                    this.calculate(this.form.weight0, this.form.weight600, 600),
                ],
            },
        ];
        let cm = [{
                name: "ความกว้างรอบอก",
                type: "column",
                data: [
                    this.form.width0,
                    this.form.width240,
                    this.form.width400,
                    this.form.width600,
                ],
            },
            {
                name: "ความยาวลำตัว",
                type: "column",
                data: [
                    this.form.length0,
                    this.form.length240,
                    this.form.length400,
                    this.form.length600,
                ],
            },
            {
                name: "ความสูง",
                type: "column",
                data: [
                    this.form.height0,
                    this.form.height240,
                    this.form.height400,
                    this.form.height600,
                ],
            },
        ];

        this.graghKK.series = kk;
        this.graghCM.series = cm;
    }
    calculate(first: any, now: any, numDay: any) {
        let result = (now - first) / numDay;
        return this.convertNum(result);
    }
    convertNum(num: any) {
        let result = num.toFixed(2);
        return Number(result);
    }
}
