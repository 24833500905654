


































































































































































































































































































































import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { Core } from "@/store/core";

import moment from "moment";
import { FarmForm } from "@/models/farm";
import { BuffaloForm } from "@/models/buffalo";
import { CoreForm } from "@/models/core";
import { App } from "@/store/app";

import PdfCert from "@/components/Component/Core/Buffalo/PdfCert.vue";
import PdfEndorse from "@/components/Component/Core/Buffalo/PdfEndorse.vue";

@Component({
  components: { PdfCert, PdfEndorse },
  computed: {},
})
export default class Farm extends Vue {
  @Prop({ default: false })
  privateMode!: boolean;

  private farm: FarmForm | any = {};
  private form: any = {};
  private formCertReal: any = {};
  private buffalo: BuffaloForm | any = {};
  private buffalos: any = [];
  private unEdit: boolean = true;
  private response: boolean = false;
  private tab: number = 0;

  private dialogFather: boolean = false;
  private dialogMother: boolean = false;
  private dialogFatherMale: boolean = false;
  private dialogMotherMale: boolean = false;
  private dialogFatherFemale: boolean = false;
  private dialogMotherFemale: boolean = false;
  private chooseFather: any | null = null;
  private chooseMother: any | null = null;
  private chooseFatherMale: any | null = null;
  private chooseMotherMale: any | null = null;
  private chooseFatherFemale: any | null = null;
  private chooseMotherFemale: any | null = null;
  api: any = process.env.VUE_APP_SERVER;
  private async created() {
    await this.run();
  }

  private async run() {
    await App.onLoad();
    this.buffalo = await Core.getHttp(
      `/user/buffalo/buffalo/${this.$route.query.id}/`
    );
    this.buffalos = await Core.putHttp(
      `/user/buffalo/buffalo/?farm=${this.buffalo.farm}`, {}
    );
    let cert = await Core.getHttp(
      `/user/buffalo/cert/${this.$route.query.id}/`
    );
    if (!cert.id) {
      let form = {
        "mother": "",
        "mother_nid": "",
        "father": "",
        "father_nid": "",
        "grand_father_male": "",
        "grand_father_male_nid": "",
        "grand_mother_male": "",
        "grand_mother_male_nid": "",
        "grand_father_female": "",
        "grand_father_female_nid": "",
        "grand_mother_female": "",
        "grand_mother_female_nid": "",
        "buffalo": this.$route.query.id
      }
      this.form = await Core.postHttp(
        `/api/buffalo/cert/create/`, form
      );

    } else {
      this.form = cert
    }

    let certReal = await Core.getHttp(
      `/user/buffalo/certreal/${this.$route.query.id}/`
    );
    if (!certReal.id) {
       let formCertReal = {
        "real_cer": null, 
        "buffalo": this.$route.query.id
      }
     this.formCertReal =  await Core.postHttp(`/api/buffalo/certreal/create/`,formCertReal)
    } else {
      this.formCertReal = await Core.getHttp(
        `/user/buffalo/certreal/${this.$route.query.id}/`
      );
    }

    this.response = true;
    await App.offLoad();
  }

  private async chooseFatherData() {
    if (this.chooseFather) {
      this.form.father = this.chooseFather.id;
      this.form.father_nid = `${this.chooseFather.name} (${this.chooseFather.nid})`;
      this.dialogFather = false;
    }
    if (this.chooseFatherMale) {
      this.form.grand_father_male = this.chooseFatherMale.id;
      this.form.grand_father_male_nid = `${this.chooseFatherMale.name} (${this.chooseFatherMale.nid})`;
      this.dialogFatherMale = false;
    }
    if (this.chooseFatherFemale) {
      this.form.grand_father_female = this.chooseFatherFemale.id;
      this.form.grand_father_female_nid = `${this.chooseFatherFemale.name} (${this.chooseFatherFemale.nid})`;
      this.dialogFatherFemale = false;
    }
  }

  private async chooseMotherData() {
    if (this.chooseMother) {
      this.form.mother = this.chooseMother.id;
      this.form.mother_nid = `${this.chooseMother.name} (${this.chooseMother.nid})`;
      this.dialogMother = false;
      console.log("Mother");
    }
    if (this.chooseMotherFemale) {
      this.form.grand_mother_female = this.chooseMotherFemale.id;
      this.form.grand_mother_female_nid = `${this.chooseMotherFemale.name} (${this.chooseMotherFemale.nid})`;
      this.dialogMotherFemale = false;
      console.log("Mother-1");
    }
    if (this.chooseMotherMale) {
      this.form.grand_mother_male = this.chooseMotherMale.id;
      this.form.grand_mother_male_nid = `${this.chooseMotherMale.name} (${this.chooseMotherMale.nid})`;
      this.dialogMotherMale = false;
      console.log("Mother-2");
    }
  }

  private async updateCert() {
    let update = await Core.putHttp(
      `/user/buffalo/cert/${this.form.id}/`,
      this.form
    );
    if (update.id) {
      await this.run();
      this.unEdit = true;
      await App.success("บันทึกข้อมูลสำเร็จ");
      await location.reload();
    } else {
      await App.error("โปรดระบุข้อมูลให้ครบถ้วน");
    }
  }

  async certChange(event: any) {
    let file = event.target.files[0];
    const form = new FormData();
    form.append("real_cer", file);
    form.append("buffalo", this.buffalo.id);
    let data = await Core.putHttp(
      `/api/buffalo/certreal/${this.formCertReal.id}/`,
      form
    );
    if (data.id) {
      await this.run();
      await App.success("บันทึกข้อมูลสำเร็จ");
    }
  }

  openCert() {
    window.open(
      `${this.api}/cert/buffalo?id=${this.$route.query.id}`,
      "_blank"
    );
  }

  openRealCret() {
    console.log(`${this.api}/${this.formCertReal.real_cer}`);
    window.open(`${this.api}/${this.formCertReal.real_cer}`, "_blank");
  }
}
