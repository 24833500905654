






















































































































































































































































import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { Core } from "@/store/core";

import moment from "moment";
import { FarmForm } from "@/models/farm";
import { BuffaloForm } from "@/models/buffalo";
import { CoreForm } from "@/models/core";
import { App } from "@/store/app";
import { User } from "@/store/user";

@Component({
  components: {},
  computed: {},
})
export default class Farm extends Vue {
  @Prop({ default: false })
  privateMode!: boolean;

  private farm: FarmForm | any = {};
  private form: BuffaloForm | any = {};
  private unEdit: boolean = true;
  private response: boolean = false;

  private sex: CoreForm[] | null = null;
  private color: CoreForm[] | null = null;
  private status: CoreForm[] | null = null;
  private froms: CoreForm[] | null = null;
  private gene: any[] | null = null;

  private publicMode: boolean = false;

  private async created() {
    await this.setChoices();
    await this.run();
  }

  private async setChoices() {
    this.sex = await Core.getChoice(`เพศควาย`);
    this.color = await Core.getChoice(`สีควาย`);
    this.status = await Core.getChoice(`สถานะควาย`);
    this.froms = await Core.getChoice(`แหล่งที่มาของควาย`);
    this.gene =  await Core.getHttp(`/user/buffalo/genes`)
  }

  private async run() {
    await App.onLoad();
    let buffalo = this.$route.query.id;
    // let profile = await Core.getHttp(`/api/profile/${user}/`)
    // this.farm = await Core.getHttp(`/user/buffalo/farm/${profile.user}/`)
    this.form = await Core.getHttp(`/user/buffalo/buffalo/${buffalo}/`);

    this.response = true;
    await App.offLoad();
  }

  @Watch("form.birthday")
  async onChangeDate(val: string) {
    this.form.age = moment().diff(val, "years", false);
    let month = (moment().diff(val, "months", false) - 12) / 12;
    this.form.age_month = Math.floor(month);
  }

  private async updateBuffalo() {
    delete this.form.back_image;
    delete this.form.front_image;
    let update = await Core.putHttp(
      `/user/buffalo/buffalo/${this.form.id}/`,
      this.form
    );
    if (update.id) {
      await this.run();
      this.unEdit = true;
      await App.success("บันทึกข้อมูลสำเร็จ");
      await location.reload();
    } else {
      await App.error("โปรดระบุข้อมูลให้ครบถ้วน");
    }
  }

  get editMode() {
    return this.unEdit ? "border-success" : "border-edit";
  }
}
